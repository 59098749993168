import React, { useContext, useEffect } from 'react';
import HomePage from '@screens/Home';
import SignIn from '@screens/SignIn';
import SignUp from '@screens/SignUp';
import Account from '@screens/Account';
import SessionContext, { Provider } from '@context/session';
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import PasswordChangePage from '@screens/PasswordChange';
import EmailChangePage from '@screens/EmailChange';
import PasswordForgotPage from '@screens/PasswordForgot';

function RouterWithProvider() {
  return <Provider>
    <Router />
  </Provider>;
}

function Router() {
  const { session, setSession } = useContext(SessionContext);
  useEffect(() => {
    const tokenFomLS = localStorage.getItem('token');
    if (!tokenFomLS) {
      setSession(false);
      return;
    }
    (async () => {
      const resp = await fetch(process.env.REACT_APP_BASE_URL + 'verify', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({token: tokenFomLS})
      });
      const respData = await resp.json();
      if (respData?.status === 'valid') {
        setSession(tokenFomLS);
      } else {
        setSession(false);
      }
    })();
  });

  if (session === null) {
    return <div>Loading...</div>;
  }
  if (!session) {
    return <BrowserRouter><Switch>
      <Route path="/sign-up"><SignUp /></Route>
      <Route path="/sign-in"><SignIn /></Route>
      <Route path="/">
        <Redirect to="/sign-in" />
      </Route>
    </Switch></BrowserRouter>;
  }
  return <BrowserRouter><Switch>
    <Route path="/account"><Account /></Route>
    <Route exact path="/password-change">
      <PasswordChangePage />
    </Route>
    <Route exact path="/email-change">
      <EmailChangePage />
    </Route>
    <Route exact path="/">
      <HomePage />
    </Route>
    <Route path="/">
      <Redirect to="/" />
    </Route>
  </Switch></BrowserRouter>;

}

export default RouterWithProvider;
